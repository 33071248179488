// 输入密码
<template>
    <el-dialog :close-on-click-modal="false"
               title="输入密码"
               append-to-body
               :visible="true"
               width="600px"
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="90px"
                 :inline="false"
                 size="normal">
            <div class="tip red mb30 ml30"
                 v-if="tip">{{tip}}</div>
            <el-row>
                <el-form-item label="密码："
                              prop="pwd">
                    <el-input v-model="form.pwd"
                              type="password"
                              placeholder="请输入密码"
                              clearable />
                </el-form-item>
                <el-form-item>
                    <el-link type="primary"
                             @click="openUpdatePwd">修改密码</el-link>
                </el-form-item>
                <el-form-item class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="onSubmit"
                               :loading="listLoading">确认</el-button>
                </el-form-item>
            </el-row>
        </el-form>
        <popUpdatePwd v-if="showPopUpdatePwd"
                      @close="closeUdatePwd"></popUpdatePwd>
    </el-dialog>

</template>

<script>
import popUpdatePwd from "./popUpdatePwd"; // 修改密码
import API from "@/api/finance.js";
export default {
    name: "popPwd",
    props: {
        // 提示信息
        tip: {
            type: String,
            default: null,
        },
    },

    watch: {},

    components: {
        popUpdatePwd,
    },

    data() {
        return {
            showPopUpdatePwd: false,
            listLoading: false,
            form: {
                pwd: "",
            }, //用户信息
            rules: {
                pwd: [
                    {
                        required: true,
                        message: "请输入密码",
                    },
                ],
            },
        };
    },

    created() {},

    mounted() {},

    methods: {
        //确认密码
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.listLoading = true;
                    this.$store
                        .dispatch("GetPublicKey")
                        .then((v) => {
                            //后端生成公钥
                            this.serverPublicKey = v.content;
                            this.listLoading = true;
                            API.checkSalaryPwd({
                                publicKey: this.serverPublicKey,
                                pwd: this.commonJs.rsaEncryption(
                                    this.form.pwd,
                                    this.serverPublicKey
                                ),
                            })
                                .then((boo) => {
                                    if (boo.content) {
                                        this.$emit("updatePwd", {
                                            pwd: this.form.pwd,
                                            random: Math.random(),
                                        });
                                        this.$emit("close");
                                    } else {
                                        this.$message({
                                            message: "密码输入错误",
                                            type: "error",
                                        });
                                    }
                                })
                                .finally(() => {
                                    this.listLoading = false;
                                });
                        })
                        .finally(() => {
                            this.listLoading = false;
                        });
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },

        // 关闭弹窗
        close() {
            this.$emit("close");
        },

        //打开修改密码
        openUpdatePwd() {
            this.showPopUpdatePwd = true;
        },

        //关闭弹框
        closeUdatePwd() {
            this.showPopUpdatePwd = false;
        },
    },

    computed: {},
};
</script>

<style lang='scss' scoped>
</style>
