// 输入密码
<template>
    <el-dialog :close-on-click-modal="false"
               append-to-body
               :visible.sync="visible"
               title="输入密码"
               width="600px"
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="120px"
                 :inline="false"
                 size="normal">
            <el-row>
                <el-form-item label="旧密码：" prop="oldPwd">
                    <el-input v-model="form.oldPwd"
                    type="password"
                              placeholder="请输入旧密码"
                              clearable />
                </el-form-item>
                <el-form-item label="新密码：" prop="newPwd">
                    <el-input v-model="form.newPwd"
                              type="password"
                              placeholder="请输入新密码"
                              clearable />
                </el-form-item>
                <el-form-item label="确认新密码：" prop="confirmPwd">
                    <el-input v-model="form.confirmPwd"
                    type="password"
                              placeholder="请确认新密码："
                              clearable />
                </el-form-item>
                <el-form-item class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="onSubmit" :loading="isloading">确认</el-button>
                </el-form-item>
            </el-row>
        </el-form>
    </el-dialog>

</template>

<script>
import humanResourcesManageApi from "@/api/finance.js";
export default {
    name: "popUpdatePwd",
    props: {},

    watch: {},

    components: {},

    data() {
        return {
            visible:true,
            isloading:false, //提交加载
            form: {
                oldPwd: "",
                newPwd: "",
                confirmPwd: "",
            },
            rules: {
                oldPwd: [
                    {
                        required: true,
                        message: "请输入旧密码",
                    },
                ],
                newPwd: [
                    {
                        required: true,
                        message: "请输入新密码",
                    },
                ],
                confirmPwd: [
                    {
                        required: true,
                        message: "请确认新密码",
                    },
                ],
            }
        };
    },

    created() {},

    mounted() {},

    methods: {
        //确认密码
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.$store.dispatch("GetPublicKey").then(v=>{
                        this.updatePwd(v.content);
                    });
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },

        /*
            修改密码
        */
        updatePwd(publicKey){
            this.isloading = true;
            humanResourcesManageApi
                .updateSalaryPwd(Object.assign({},this.form,{
                    oldPwd:this.commonJs.rsaEncryption(this.form.oldPwd,publicKey),
                    newPwd:this.commonJs.rsaEncryption(this.form.newPwd,publicKey),
                    confirmPwd:this.commonJs.rsaEncryption(this.form.confirmPwd,publicKey),
                    publicKey:publicKey
                }))
                .then((res) => {
                    this.isloading = false;
                    this.$message({type:"success" , message:res.content || '修改成功'});
                    this.$emit("close");
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {},
};
</script>

<style lang='scss' scoped>
</style>
