// 五险一金表单
<template>
    <div class="comCalcsocialSecurity">
        <div class="popItemBox">
            <el-tabs type="border-card">
                <el-tab-pane label="五险一金">
                    <el-row :gutter="60">
                        <el-col :span="8">
                            <el-form-item label="养老保险基数">
                                <el-input-money
                                    v-model="form.endowmentInsuranceBase"
                                    :disabled="disabled"
                                    @input="
                                        changeWXYJ({
                                            base: 'endowmentInsuranceBase',
                                            person: 'endowmentInsuranceStaff',
                                            company:
                                                'endowmentInsuranceCompany',
                                            personPer:
                                                form.endowmentInsuranceStaffPercent,
                                            companyPer:
                                                form.endowmentInsuranceCompanyPercent,
                                        })
                                    "
                                    placeholder="请输入养老保险基数"
                                ></el-input-money>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="养老保险-个人">
                                <el-input
                                    v-model="form.endowmentInsuranceStaff"
                                    disabled
                                    placeholder="请输入养老保险-个人"
                                >
                                    <div slot="suffix" class="inputUnit">
                                        {{
                                            form.endowmentInsuranceStaffPercent ||
                                                0
                                        }}%
                                    </div>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="养老保险-单位">
                                <el-input
                                    v-model="form.endowmentInsuranceCompany"
                                    disabled
                                    placeholder="请输入养老保险-单位"
                                >
                                    <div slot="suffix" class="inputUnit">
                                        {{
                                            form.endowmentInsuranceCompanyPercent ||
                                                0
                                        }}%
                                    </div>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="60">
                        <el-col :span="8">
                            <el-form-item label="医疗保险基数">
                                <el-input-money
                                    v-model="form.medicalInsuranceBase"
                                    :disabled="disabled"
                                    @input="
                                        changeWXYJ({
                                            base: 'medicalInsuranceBase',
                                            person: 'medicalInsuranceStaff',
                                            company: 'medicalInsuranceCompany',
                                            personPer:
                                                form.medicalInsuranceStaffPercent,
                                            companyPer:
                                                form.medicalInsuranceCompanyPercent,
                                        })
                                    "
                                    placeholder="请输入医疗保险基数"
                                ></el-input-money>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="医疗保险-个人">
                                <el-input
                                    v-model="form.medicalInsuranceStaff"
                                    disabled
                                    placeholder="请输入医疗保险-个人"
                                >
                                    <div slot="suffix" class="inputUnit">
                                        {{
                                            form.medicalInsuranceStaffPercent ||
                                                0
                                        }}%
                                    </div>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="医疗保险-单位">
                                <el-input
                                    v-model="form.medicalInsuranceCompany"
                                    disabled
                                    placeholder="请输入医疗保险-单位"
                                >
                                    <div slot="suffix" class="inputUnit">
                                        {{
                                            form.medicalInsuranceCompanyPercent ||
                                                0
                                        }}%
                                    </div>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="60">
                        <el-col :span="8">
                            <el-form-item label="失业保险基数">
                                <el-input-money
                                    v-model="form.unemploymentInsuranceBase"
                                    :disabled="disabled"
                                    @input="
                                        changeWXYJ({
                                            base: 'unemploymentInsuranceBase',
                                            person:
                                                'unemploymentInsuranceStaff',
                                            company:
                                                'unemploymentInsuranceCompany',
                                            personPer:
                                                form.unemploymentInsuranceStaffPercent,
                                            companyPer:
                                                form.unemploymentInsuranceCompanyPercent,
                                        })
                                    "
                                    placeholder="请输入失业保险基数"
                                ></el-input-money>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="失业保险-个人">
                                <el-input
                                    v-model="form.unemploymentInsuranceStaff"
                                    disabled
                                    placeholder="请输入失业保险-个人"
                                >
                                    <div slot="suffix" class="inputUnit">
                                        {{
                                            form.unemploymentInsuranceStaffPercent ||
                                                0
                                        }}%
                                    </div>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="失业保险-单位">
                                <el-input
                                    v-model="form.unemploymentInsuranceCompany"
                                    disabled
                                    placeholder="请输入失业保险-单位"
                                >
                                    <div slot="suffix" class="inputUnit">
                                        {{
                                            form.unemploymentInsuranceCompanyPercent ||
                                                0
                                        }}%
                                    </div>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="60">
                        <el-col :span="8">
                            <el-form-item label="工伤保险基数">
                                <el-input-money
                                    v-model="form.employmentInjuryInsuranceBase"
                                    :disabled="disabled"
                                    @input="
                                        changeWXYJ({
                                            base:
                                                'employmentInjuryInsuranceBase',
                                            person:
                                                'employmentInjuryInsuranceStaff',
                                            company:
                                                'employmentInjuryInsuranceCompany',
                                            personPer:
                                                form.employmentInjuryInsuranceStaffPercent,
                                            companyPer:
                                                form.employmentInjuryInsuranceCompanyPercent,
                                        })
                                    "
                                    placeholder="请输入工伤保险基数"
                                ></el-input-money>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="工伤保险-个人">
                                <el-input
                                    v-model="
                                        form.employmentInjuryInsuranceStaff
                                    "
                                    disabled
                                    placeholder=""
                                >
                                    <div slot="suffix" class="inputUnit">
                                        {{
                                            form.employmentInjuryInsuranceStaffPercent ||
                                                0
                                        }}%
                                    </div>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="工伤保险-单位">
                                <el-input
                                    v-model="
                                        form.employmentInjuryInsuranceCompany
                                    "
                                    disabled
                                    placeholder="请输入工伤保险-单位"
                                >
                                    <div slot="suffix" class="inputUnit">
                                        {{
                                            form.employmentInjuryInsuranceCompanyPercent ||
                                                0
                                        }}%
                                    </div>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="60">
                        <el-col :span="8">
                            <el-form-item label="生育保险基数">
                                <el-input-money
                                    v-model="form.birthInsuranceBase"
                                    :disabled="disabled"
                                    @input="
                                        changeWXYJ({
                                            base: 'birthInsuranceBase',
                                            person: 'birthInsuranceStaff',
                                            company: 'birthInsuranceCompany',
                                            personPer:
                                                form.birthInsuranceStaffPercent,
                                            companyPer:
                                                form.birthInsuranceCompanyPercent,
                                        })
                                    "
                                    placeholder="请输入生育保险基数"
                                ></el-input-money>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="生育保险-个人">
                                <el-input
                                    disabled
                                    v-model="form.birthInsuranceStaff"
                                    placeholder=""
                                >
                                    <div slot="suffix" class="inputUnit">
                                        {{
                                            form.birthInsuranceStaffPercent ||
                                                0
                                        }}%
                                    </div>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="生育保险-单位">
                                <el-input
                                    v-model="form.birthInsuranceCompany"
                                    disabled
                                    placeholder="请输入生育保险-单位"
                                >
                                    <div slot="suffix" class="inputUnit">
                                        {{
                                            form.birthInsuranceCompanyPercent ||
                                                0
                                        }}%
                                    </div>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="60">
                        <el-col :span="8">
                            <el-form-item label="公积金基数">
                                <el-input-money
                                    v-model="form.accumulationFundBase"
                                    :disabled="disabled"
                                    @input="
                                        changeWXYJ({
                                            base: 'accumulationFundBase',
                                            person: 'accumulationFundStaff',
                                            company: 'accumulationFundCompany',
                                            personPer:
                                                form.accumulationFundStaffPercent,
                                            companyPer:
                                                form.accumulationFundCompanyPercent,
                                        })
                                    "
                                    placeholder="请输入公积金基数"
                                ></el-input-money>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="公积金-个人">
                                <el-input
                                    v-model="form.accumulationFundStaff"
                                    disabled
                                    placeholder="请输入公积金-个人"
                                >
                                    <div slot="suffix" class="inputUnit">
                                        {{
                                            form.accumulationFundStaffPercent ||
                                                0
                                        }}%
                                    </div>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="公积金-单位">
                                <el-input
                                    v-model="form.accumulationFundCompany"
                                    disabled
                                    placeholder="请输入公积金-单位"
                                >
                                    <div slot="suffix" class="inputUnit">
                                        {{
                                            form.accumulationFundCompanyPercent ||
                                                0
                                        }}%
                                    </div>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="60">
                        <el-col :span="8">
                            <el-form-item label="大病医疗保险金额">
                                <el-input-money
                                    v-model="form.seriousSickInsurance"
                                    :disabled="disabled"
                                    placeholder="请输入大病医疗保险金额"
                                ></el-input-money>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <div class="countBox">
                        <div class="countItem">
                            <div class="count">{{ fiveInsurances || 0.0 }}</div>
                            <div class="unit">五险一金总数（元）</div>
                            <div class="line"></div>
                        </div>
                        <div class="countItem green">
                            <div class="count">
                                {{ personfiveInsurances || 0.0 }}
                            </div>
                            <div class="unit">个人总数（元）</div>
                            <div class="line"></div>
                        </div>
                        <div class="countItem purple">
                            <div class="count">
                                {{ companyfiveInsurances || 0.0 }}
                            </div>
                            <div class="unit">单位总数（元）</div>
                            <div class="line"></div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import ElInputMoney from "../../../components/form/elInputMoney";
import API from "@/api/finance.js";
// import rsa from "@/utils/encryption/rsa"; // RSA解密
export default {
    name: "comCalcsocialSecurity",

    props: {
        // 详情获取的表单数据回显
        formData: {
            type: Object,
            default() {
                return {};
            },
        },
        // 是否需要重新拉取配置信息
        needLoadConfig: {
            type: Boolean,
            default: true,
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },
        //是否需要更新form  父级传递数据替换
        refreshWxyj: {},
    },

    components: {
        ElInputMoney,
    },

    data() {
        return {
            form: {
                endowmentInsuranceBase: 0, //养老保险个人基数
                endowmentInsuranceStaffPercent: 0, //养老保险个人比例
                endowmentInsuranceCompanyPercent: 0, //养老保险单位比例
                medicalInsuranceBase: 0, //医疗保险个人基数
                medicalInsuranceStaffPercent: 0, //医疗保险个人比例
                medicalInsuranceCompanyPercent: 0, //医疗保险单位比例
                unemploymentInsuranceBase: 0, //失业保险个人基数
                unemploymentInsuranceStaffPercent: 0, //失业保险个人比例
                unemploymentInsuranceCompanyPercent: 0, //失业保险单位比例
                employmentInjuryInsuranceBase: 0, //工伤保险个人基数
                employmentInjuryInsuranceStaffPercent: 0, //工伤保险个人比例
                employmentInjuryInsuranceCompanyPercent: 0, //工伤保险单位比例
                birthInsuranceBase: 0, //生育保险个人基数
                birthInsuranceStaffPercent: 0, //生育保险个人比例
                birthInsuranceCompanyPercent: 0, //生育保险单位比例
                accumulationFundBase: 0, //公积金个人基数
                accumulationFundStaffPercent: 0, //公积金个人比例
                accumulationFundCompanyPercent: 0, //公积金单位比例
                seriousSickInsurance: 0, // 大病医疗保险金额
                birthInsuranceStaff: 0, //
                companyFiveInsurances: 0, //
                employmentInjuryInsuranceStaff: 0, //
                fiveInsurances: 0, //
                socialSecurityCompany: 0, //
                socialSecurityStaff: 0, //
                staffFiveInsurances: 0, //
            }, //税率
            decryFieldArr: [
                "accumulationFundBase",
                "accumulationFundCompany",
                "accumulationFundCompanyPercent",
                "accumulationFundStaff",
                "accumulationFundStaffPercent",
                "birthInsuranceBase",
                "birthInsuranceCompany",
                "birthInsuranceCompanyPercent",
                "birthInsuranceStaff",
                "birthInsuranceStaffPercent",
                "companyFiveInsurances",
                "employmentInjuryInsuranceBase",
                "employmentInjuryInsuranceCompany",
                "employmentInjuryInsuranceCompanyPercent",
                "employmentInjuryInsuranceStaff",
                "employmentInjuryInsuranceStaffPercent",
                "endowmentInsuranceBase",
                "endowmentInsuranceCompany",
                "endowmentInsuranceCompanyPercent",
                "endowmentInsuranceStaff",
                "endowmentInsuranceStaffPercent",
                "fiveInsurances",
                "medicalInsuranceBase",
                "medicalInsuranceCompany",
                "medicalInsuranceCompanyPercent",
                "medicalInsuranceStaff",
                "medicalInsuranceStaffPercent",
                "socialSecurityCompany",
                "socialSecurityStaff",
                "staffFiveInsurances",
                "unemploymentInsuranceBase",
                "unemploymentInsuranceCompany",
                "unemploymentInsuranceCompanyPercent",
                "unemploymentInsuranceStaff",
                "unemploymentInsuranceStaffPercent",
                "seriousSickInsurance",
            ], //需要解密/加密的字段
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 获取公司五险一金缴费比例  包括基数
        getCompanyBl() {
            API.getSocialSecurity().then((res) => {
                this.$set(this, "form", res.content);
                this.$emit("changeSecurity", res.content);
                // this.form = res.content;
            });
        },

        /**
         * 输入五险一金计算
         * base: 基数字段名
         * person: 个人字段名
         * company: 公司字段名
         * personPer: 个人缴纳比例
         * companyPer: 公司缴纳比例
         */
        changeWXYJ(info) {
            if (info && this.form[info.base]) {
                if (info.person) {
                    this.$set(
                        this.form,
                        info.person,
                        this.commonJs.keepTwoDecimal(
                            (this.form[info.base] *
                                parseFloat(info.personPer)) /
                                100
                        )
                    );
                }
                this.$set(
                    this.form,
                    info.company,
                    this.commonJs.keepTwoDecimal(
                        (this.form[info.base] * parseFloat(info.companyPer)) /
                            100
                    )
                );
            } else {
                this.$set(this.form, info.person, 0.0);
                this.$set(this.form, info.company, 0.0);
            }
        },

        //获取加密后的社保数据
        entrySecurity(publicKey) {
            return Object.assign(
                this.commonJs.encryMapDataByArr(
                    this.commonJs.deepCopy(this.form),
                    this.decryFieldArr,
                    publicKey
                ),
                {
                    publicKey: publicKey,
                }
            );
        },

        /*  获取后台公钥  、  生成前端加解密公钥、私钥 */
        getEntrySecurity() {
            return new Promise((res, rject) => {
                this.$store.dispatch("getSalaryKey").then((v) => {
                    this.fontRsaKey = v;
                    this.$store
                        .dispatch("GetPublicKey")
                        .then((v) => {
                            //后端生成公钥
                            this.serverPublicKey = v.content;
                            res(this.entrySecurity(v.content));
                        })
                        .catch(() => {
                            rject();
                        });
                });
            });
        },
    },

    computed: {
        //五险一金总数
        fiveInsurances() {
            let num = (
                parseFloat(this.personfiveInsurances) +
                parseFloat(this.companyfiveInsurances)
            ).toFixed(2);
            return num;
        },

        //五险一金个人总数
        personfiveInsurances() {
            let num = (
                parseFloat(this.form.endowmentInsuranceStaff || "0.00") +
                parseFloat(this.form.medicalInsuranceStaff || "0.00") +
                parseFloat(this.form.unemploymentInsuranceStaff || "0.00") +
                parseFloat(this.form.employmentInjuryInsuranceStaff || "0.00") +
                parseFloat(this.form.birthInsuranceStaff || "0.00") +
                parseFloat(this.form.accumulationFundStaff || "0.00")
            ).toFixed(2);
            return num;
        },

        //五险一金单位总数
        companyfiveInsurances() {
            let num = (
                parseFloat(this.form.endowmentInsuranceCompany || "0.00") +
                parseFloat(this.form.medicalInsuranceCompany || "0.00") +
                parseFloat(this.form.unemploymentInsuranceCompany || "0.00") +
                parseFloat(
                    this.form.employmentInjuryInsuranceCompany || "0.00"
                ) +
                parseFloat(this.form.birthInsuranceCompany || "0.00") +
                parseFloat(this.form.accumulationFundCompany || "0.00") +
                parseFloat(this.form.seriousSickInsurance || "0.00")
            ).toFixed(2);
            return num;
        },
    },

    watch: {
        form: {
            deep: true,
            immediate: true,
            handler: function() {
                // TODO v3.13 优化加密字段
                let data = {
                    endowmentInsuranceBase: this.form.endowmentInsuranceBase, //养老保险个人基数
                    endowmentInsuranceStaffPercent: this.form
                        .endowmentInsuranceStaffPercent, //养老保险个人比例
                    endowmentInsuranceCompanyPercent: this.form
                        .endowmentInsuranceCompanyPercent, //养老保险单位比例
                    medicalInsuranceBase: this.form.medicalInsuranceBase, //医疗保险个人基数
                    medicalInsuranceStaffPercent: this.form
                        .medicalInsuranceStaffPercent, //医疗保险个人比例
                    medicalInsuranceCompanyPercent: this.form
                        .medicalInsuranceCompanyPercent, //医疗保险单位比例
                    unemploymentInsuranceBase: this.form
                        .unemploymentInsuranceBase, //失业保险个人基数
                    unemploymentInsuranceStaffPercent: this.form
                        .unemploymentInsuranceStaffPercent, //失业保险个人比例
                    unemploymentInsuranceCompanyPercent: this.form
                        .unemploymentInsuranceCompanyPercent, //失业保险单位比例
                    employmentInjuryInsuranceBase: this.form
                        .employmentInjuryInsuranceBase, //工伤保险个人基数
                    employmentInjuryInsuranceStaffPercent: this.form
                        .employmentInjuryInsuranceStaffPercent, //工伤保险个人比例
                    employmentInjuryInsuranceCompanyPercent: this.form
                        .employmentInjuryInsuranceCompanyPercent, //工伤保险单位比例
                    birthInsuranceBase: this.form.birthInsuranceBase, //生育保险个人基数
                    birthInsuranceStaffPercent: this.form
                        .birthInsuranceStaffPercent, //生育保险个人比例
                    birthInsuranceCompanyPercent: this.form
                        .birthInsuranceCompanyPercent, //生育保险单位比例
                    accumulationFundBase: this.form.accumulationFundBase, //公积金个人基数
                    accumulationFundStaffPercent: this.form
                        .accumulationFundStaffPercent, //公积金个人比例
                    accumulationFundCompanyPercent: this.form
                        .accumulationFundCompanyPercent, //公积金单位比例
                    endowmentInsuranceStaff: this.form.endowmentInsuranceStaff,
                    endowmentInsuranceCompany: this.form
                        .endowmentInsuranceCompany,
                    medicalInsuranceStaff: this.form.medicalInsuranceStaff,
                    medicalInsuranceCompany: this.form.medicalInsuranceCompany,
                    unemploymentInsuranceStaff: this.form
                        .unemploymentInsuranceStaff,
                    unemploymentInsuranceCompany: this.form
                        .unemploymentInsuranceCompany,
                    employmentInjuryInsuranceCompany: this.form
                        .employmentInjuryInsuranceCompany,
                    birthInsuranceCompany: this.form.birthInsuranceCompany,
                    accumulationFundStaff: this.form.accumulationFundStaff,
                    accumulationFundCompany: this.form.accumulationFundCompany,
                    employmentInjuryInsuranceStaff: this.form
                        .employmentInjuryInsuranceStaff,
                    birthInsuranceStaff: this.form.birthInsuranceStaff,
                    seriousSickInsurance: this.form.seriousSickInsurance,
                };
                this.$emit("changeSecurity", data);
            },
        },

        //父级传递过来的数据
        refreshWxyj: {
            deep: true,
            immediate: true,
            handler: function(obj) {
                obj &&
                    this.$set(
                        this,
                        "form",
                        this.commonJs.deepCopy(this.formData)
                    );
            },
        },
        // 父组件传递是否需要配置信息
        needLoadConfig: {
            deep: true,
            immediate: true,
            handler: function(val) {
                if (val) {
                    this.getCompanyBl();
                } else {
                    const {
                        endowmentInsuranceBase,
                        endowmentInsuranceStaffPercent,
                        endowmentInsuranceCompanyPercent,
                        medicalInsuranceBase,
                        medicalInsuranceStaffPercent,
                        medicalInsuranceCompanyPercent,
                        unemploymentInsuranceBase,
                        unemploymentInsuranceStaffPercent,
                        unemploymentInsuranceCompanyPercent,
                        employmentInjuryInsuranceBase,
                        employmentInjuryInsuranceStaffPercent,
                        employmentInjuryInsuranceCompanyPercent,
                        birthInsuranceBase,
                        birthInsuranceStaffPercent,
                        birthInsuranceCompanyPercent,
                        accumulationFundBase,
                        accumulationFundStaffPercent,
                        accumulationFundCompanyPercent,
                        endowmentInsuranceStaff,
                        endowmentInsuranceCompany,
                        medicalInsuranceStaff,
                        medicalInsuranceCompany,
                        unemploymentInsuranceStaff,
                        unemploymentInsuranceCompany,
                        employmentInjuryInsuranceCompany,
                        birthInsuranceCompany,
                        accumulationFundStaff,
                        accumulationFundCompany,
                        employmentInjuryInsuranceStaff,
                        birthInsuranceStaff,
                        seriousSickInsurance,
                    } = this.formData;
                    this.form.endowmentInsuranceBase = endowmentInsuranceBase;
                    this.form.endowmentInsuranceStaffPercent = endowmentInsuranceStaffPercent;
                    this.form.endowmentInsuranceCompanyPercent = endowmentInsuranceCompanyPercent;
                    this.form.medicalInsuranceBase = medicalInsuranceBase;
                    this.form.medicalInsuranceStaffPercent = medicalInsuranceStaffPercent;
                    this.form.medicalInsuranceCompanyPercent = medicalInsuranceCompanyPercent;
                    this.form.unemploymentInsuranceBase = unemploymentInsuranceBase;
                    this.form.unemploymentInsuranceStaffPercent = unemploymentInsuranceStaffPercent;
                    this.form.unemploymentInsuranceCompanyPercent = unemploymentInsuranceCompanyPercent;
                    this.form.employmentInjuryInsuranceBase = employmentInjuryInsuranceBase;
                    this.form.employmentInjuryInsuranceStaffPercent = employmentInjuryInsuranceStaffPercent;
                    this.form.employmentInjuryInsuranceCompanyPercent = employmentInjuryInsuranceCompanyPercent;
                    this.form.birthInsuranceBase = birthInsuranceBase;
                    this.form.birthInsuranceStaffPercent = birthInsuranceStaffPercent;
                    this.form.birthInsuranceCompanyPercent = birthInsuranceCompanyPercent;
                    this.form.accumulationFundBase = accumulationFundBase;
                    this.form.accumulationFundStaffPercent = accumulationFundStaffPercent;
                    this.form.accumulationFundCompanyPercent = accumulationFundCompanyPercent;
                    this.form.endowmentInsuranceStaff = endowmentInsuranceStaff;
                    this.form.endowmentInsuranceCompany = endowmentInsuranceCompany;
                    this.form.medicalInsuranceStaff = medicalInsuranceStaff;
                    this.form.medicalInsuranceCompany = medicalInsuranceCompany;
                    this.form.unemploymentInsuranceStaff = unemploymentInsuranceStaff;
                    this.form.unemploymentInsuranceCompany = unemploymentInsuranceCompany;
                    this.form.employmentInjuryInsuranceCompany = employmentInjuryInsuranceCompany;
                    this.form.birthInsuranceCompany = birthInsuranceCompany;
                    this.form.accumulationFundStaff = accumulationFundStaff;
                    this.form.accumulationFundCompany = accumulationFundCompany;
                    this.form.employmentInjuryInsuranceStaff = employmentInjuryInsuranceStaff;
                    this.form.birthInsuranceStaff = birthInsuranceStaff;
                    this.form.seriousSickInsurance = seriousSickInsurance;
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
// .comCalcsocialSecurity{

// }
.countItem {
    width: 250px;
}
</style>
